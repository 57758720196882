import { useUiStore } from "~/store/ui";
export default (function (context, inject) {
  var _useUiStore = useUiStore(),
    addToast = _useUiStore.addToast,
    closeToast = _useUiStore.closeToast;
  inject('toasts', {
    add: function add(toast) {
      return addToast(toast);
    },
    close: function close(toast) {
      return closeToast(toast);
    }
  });
});