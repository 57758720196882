import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: 'user',
  state(){
    return {
      authToken: null,
      adminToken: null,
      shopifyApp: null,
      shopifyHost: null,
      shopifyRedirect: null,
    }
  },
  actions:  {
    setAdminToken(adminToken) {
      this.adminToken = adminToken

      try {
          sessionStorage.setItem('salesPopAdminToken', adminToken)
      } catch(e) {
          console.log('Skipping sessionStorage `salesPopAdminToken` set. No sessionStorage available.')
      }
    },
    setAuthToken(authToken) {
      this.authToken = authToken

      try {
          sessionStorage.setItem('salesPopAuthToken', authToken)
      } catch (e) {
          console.log('Skipping sessionStorage `salesPopAuthToken` set. No sessionStorage available.')
      }
    },
    setAuthTokenFromSessionStorage() {
      try {
        this.authToken = sessionStorage.getItem('salesPopAuthToken')
      } catch(e) {
        console.log('Skipping sessionStorage `salesPopAuthToken` retrieval. No sessionStorage available.')
      }
    },
    setAdminTokenFromSessionStorage() {
      try {
        this.adminToken = sessionStorage.getItem('salesPopAdminToken')
      } catch(e) {
        console.log('Skipping sessionStorage `salesPopAdminToken` retrieval. No sessionStorage available.')
      }
    },
  }
})
