import { defineStore } from "pinia";
import { useShepherd } from "vue-shepherd";
import 'shepherd.js/dist/css/shepherd.css';
import '../../../assets/css/onboarding.css'
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";
import { usePopsDiscountsStore } from "~/store/pops/discounts";
import { useSiteStore } from "~/store/site";

export const useDiscountsOnboardingStore = defineStore({
    id: 'discount_pops',
    state: () => {
        return {
            onboarding: useShepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shadow-md bg-purple-dark',
                    scrollTo: true
                }
            }),
            steps: [
                {
                    id: 'start',
                    text: '<p class="onboarding-text">Display active discounts while customers are browsing the store. With Discounts by POP! you can create a sense of urgency turning casual browsers into shoppers. The code will be copied to the clipboard when a customer clicks on the notification.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                await $nuxt.$router.push('/pops/rules-and-settings/');
                                useDiscountsOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'discounts_menu',
                    text: '<p class="onboarding-text">Choose the Discounts in Pop Rules & Settings to modify yor discount.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartDiscountMenuButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartDiscountMenuButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartDiscountMenuButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const menuButton = document.getElementById("popsRulesAndSettingAddToCartDiscountMenuButton");
                                menuButton.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'binary_switch_button',
                    text: '<p class="onboarding-text">Click on the toggle to activate Discount Pops.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingAddToCartBinarySwitch',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartBinarySwitch",
                        event: 'click',
                    },
                    showOn: () => {
                        if (useSiteStore().currentSite.force_auth_scopes_reauth) {
                            return true;
                        }
                        return false;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingAddToCartBinarySwitch';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const binarySwitch = document.getElementById("popsRulesAndSettingAddToCartBinarySwitch")
                                binarySwitch.click()
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'reauth_smart_dialog',
                    text: `<p class="onboarding-text">Click to authorize permissions required for Discount Pops</p>`,
                    attachTo: {
                        element: '#popsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton',
                        on: 'top'
                    },
                    classes: 'custom-content',
                    showOn: () => {
                        if (useSiteStore().currentSite.force_auth_scopes_reauth) {
                            return true;
                        }
                        return false;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const reauthButton = document.getElementById("popsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton")
                                reauthButton.click()
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'edit_rules_and_design_button',
                    text: '<p class="onboarding-text">Click on the button or choose Continue to go to editing discount page.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingAddToCartEditRulesAndDesignButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartEditRulesAndDesignButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingAddToCartEditRulesAndDesignButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/pops/rules-and-settings/discounts/');
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_new_discount_selector',
                    text: '<p class="onboarding-text">Search and import discounts from your Shopify Admin.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingSelectShopifyDiscount',
                        on: 'left'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingSelectShopifyDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog")
                                if(!closeModal){
                                    resolve();
                                }
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const selector = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelector");
                                selector.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_search_discount',
                    text: `<p class="onboarding-text-horizontal-step">Search your discounts from Shopify Admin.
                          Discounts that match our requirements are highlighted in purple, those that are highlighted in grey cannot be selected.</p>`,
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog',
                        on: 'top'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    beforeShowPromise: async() => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog';
                            const discountStore = usePopsDiscountsStore();
                            discountStore.shopifyDiscounts = [];

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(async () => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () =>{
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_choose_discount',
                    text: '<p class="onboarding-text">Choose your discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogDiscounts',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogAvailableDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [

                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () => {
                                const discountStore = usePopsDiscountsStore();
                                discountStore.shopifyDiscounts = [];

                                useDiscountsOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_submit_discount',
                    text: '<p class="onboarding-text">Save your chosen discount.</p>',
                    attachTo: {
                        element: '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: ".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit",
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const submitButton = document.querySelector(".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit");
                                submitButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'design_in_sidebar_menu',
                    text: '<p class="onboarding-text">In this section, you can customize the message to match your brand’s voice.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingDesignMenuButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingDesignMenuButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().skipDiscountSteps = false;
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingDesignMenuButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/pops/rules-and-settings/design');
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'background_color_change',
                    text: '<p class="onboarding-text">Change the background color for your discount.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingChangeBackgroundColor',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingChangeBackgroundColor';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_background',
                    text: '<p class="onboarding-text">Your new background color.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingsPopModel',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingsPopModel';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_background',
                    text: '<p class="onboarding-text">Save your changed background for discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSettingAddToCartSettingsSave",
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                saveButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'theme_in_sidebar_menu',
                    text: '<p class="onboarding-text">You can choose the theme of your notification to make it stand out and grab your visitor’s attention.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingThemesMenuButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingThemesMenuButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        selector: "#popsRulesAndSettingThemesMenuButton",
                        event: 'click',
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/pops/rules-and-settings/themes');
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'theme_selector',
                    text: '<p class="onboarding-text">Change the theme of your discount.</p>',
                    attachTo: {
                        element: '.popsRulesAndSettingsThemesSelector',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.popsRulesAndSettingsThemesSelector';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_theme',
                    text: '<p class="onboarding-text">Your new theme.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingsPopModel',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingsPopModel';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_theme',
                    text: '<p class="onboarding-text">Save your changed theme.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSettingAddToCartSettingsSave",
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                saveButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useDiscountsOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'completed',
                    text: `
                        <div class="onboarding-container">
                            <h1 class="onboarding-title">Congratulations on completing onboarding!🎉🎉🎉</h1>
                            <p class="onboarding-text">
                            You have successfully completed all the steps and now have the knowledge
                            of how to choose a discount and change the design of your popup.</p>
                        </div>`,
                    classes: 'custom-start-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useDiscountsOnboardingStore().onboarding.getCurrentStep().id, reloadOnboardings: true });
                            useBaseOnboardingStore().completedOnboarding = true;
                            useBaseOnboardingStore().currentOnboarding = null;

                            resolve();
                        });
                    },
                    buttons: [
                        {
                            text: 'Finish onboardings',
                            classes: 'custom-button',
                            action: async () => {
                                useDiscountsOnboardingStore().onboarding.complete();
                                await $nuxt.$router.push('/dashboard');
                            }
                        },
                    ],
                },
            ],
        }
    },
})
