import {defineStore} from 'pinia'
import moment from 'moment'
import {useTemplatesStore} from './templates'

export const useUiStore = defineStore({
    id: 'ui',
    state() {
        return {
            sidebarIsOpen: false,
            loadingPreflight: true,
            defaultTemplateHandle: 'shopify_storefront_add_to_cart',
            editingPopHandler: '',
            toasts: [],
            planChangeModal: false,
            showRedirectToDashboardChangeModal: false,
            metricPeriodsList: [{
                label: 'Today',
                dateType: 'startOfDate',
                granularity: 'day',
            }, {
                label: 'Yesterday',
                dateType: 'startOfDate',
                granularity: 'day',
                offset: 1,
            }, {
                label: 'This Week',
                dateType: 'startOfDate',
                granularity: 'isoWeek',
            }, {
                label: 'This Month',
                dateType: 'startOfDate',
                granularity: 'month',
            }, {
                label: 'This Quarter',
                dateType: 'startOfDate',
                granularity: 'quarter',
            }, {
                label: 'This Year',
                dateType: 'startOfDate',
                granularity: 'year',
            }, {
                label: 'Last Week',
                dateType: 'startOfDate',
                granularity: 'isoWeek',
                offset: 1,
            }, {
                label: 'Last Month',
                dateType: 'startOfDate',
                granularity: 'month',
                offset: 1,
            }, {
                label: 'Last Quarter',
                dateType: 'startOfDate',
                granularity: 'quarter',
                offset: 1,
            }, {
                label: 'Last Year',
                dateType: 'startOfDate',
                granularity: 'year',
                offset: 1,
            }],
            languages: [
                {
                    value: 'en',
                    label: 'English'
                },
                {
                    value: 'es',
                    label: 'Spanish'
                },
                {
                    value: 'fr',
                    label: 'French'
                },
                {
                    value: 'pt',
                    label: 'Portugese'
                },
                {
                    value: 'nl',
                    label: 'Dutch'
                },
                {
                    value: 'it',
                    label: 'Italian'
                },
                {
                    value: 'da',
                    label: 'Danish'
                },
                {
                    value: 'de',
                    label: 'German'
                },
                {
                    value: 'zh',
                    label: 'Chinese'
                },
                {
                    value: 'ro',
                    label: 'Romanian'
                },
                {
                    value: 'nb',
                    label: 'Norwegian'
                },
                {
                    value: 'sv',
                    label: 'Swedish'
                },
                {
                    value: 'fi',
                    label: 'Finnish'
                },
                {
                    value: 'id',
                    label: 'Indonesian'
                },
                {
                    value: 'hu',
                    label: 'Hungarian'
                },
                {
                    value: 'he',
                    label: 'Hebrew'
                },
                {
                    value: 'cs',
                    label: 'Czech'
                },
            ],
            popRules: {
                drawerMenuButtons: [{
                    icon: 'star',
                    slug: 'branding',
                    label: 'Branding',
                    subtitle: 'Optional',
                    source: 'shopify'
                }, {
                    icon: 'globe',
                    slug: 'language',
                    label: 'Language',
                    subtitle: 'Change pop language',
                }, {
                    icon: 'desktop-alt',
                    slug: 'devices',
                    label: 'Devices',
                    subtitle: 'Choose Devices'
                }, {
                    icon: 'border-right',
                    slug: 'position',
                    label: 'Position',
                    subtitle: 'Choose Pop Position'
                }, {
                    icon: 'clock-nine',
                    slug: 'timing',
                    label: 'Timing',
                    subtitle: 'Choose how long to show pops'
                }, {
                    icon: 'arrow-resize-diagonal',
                    slug: 'size',
                    label: 'Pop Size',
                    subtitle: 'Choose large or small.'
                }, {
                    icon: 'user',
                    slug: 'uniq-sessions-per-visitor',
                    label: 'Unique Sessions per visitor',
                    subtitle: 'Set max sessions per visitor'
                }],
                popDevices: [{
                    text: 'mobile',
                    icon: 'mobile-android-alt',
                    settingsKey: 'proof_mobile_enabled'
                }, {
                    text: 'tablet',
                    icon: 'tablet',
                    settingsKey: 'proof_tablet_enabled'
                }, {
                    text: 'desktop',
                    icon: 'desktop',
                    settingsKey: 'proof_desktop_enabled'
                }]
            }
        }
    },
    getters: {
        metricPeriods() {
            const startOfDateFunction = (period) => ({
                from: moment()
                    .startOf(period.granularity)
                    .subtract(period.offset ? period.offset : 0, period.granularity),
                to: moment()
                    .endOf(period.granularity)
                    .subtract(period.offset ? period.offset : 0, period.granularity),
            })

            return this.metricPeriodsList.map(period => {
                let momentFunction
                if (period.dateType === 'startOfDate') momentFunction = startOfDateFunction
                return {
                    label: period.label,
                    granularity: period.granularity,
                    current: {
                        ...momentFunction(period),
                    }
                }
            })
        },
    },
    actions: {
        addToast(toast) {
            const foundIndex = this.toasts.findIndex(({title}) => title == toast.title)
            if (toast.type === 'error') {
                // this.$logRocket.captureException(toast.e)
            }
            if (foundIndex !== -1) {
                this.toasts[foundIndex].ocurrences++
            } else {
                this.toasts.push({
                    ...toast,
                    ocurrences: 1
                })
            }
            setTimeout(() => {
                this.deleteToast(toast)
            }, 5000)
        },
        closeToast(toast) {
            this.deleteToast(toast)
        },
        setTemplateHandler(handle) {
            const templatesStore = useTemplatesStore()
            templatesStore.currentTemplate = templatesStore.originalTemplates.find(
                template => template.handle === handle
            )
            this.editingPopHandler = handle
            templatesStore.setupTemplateSettings()
        },
        deleteToast(toast) {
            const foundIndex = this.toasts.findIndex(({title}) => title == toast.title)
            if (foundIndex == -1) return

            const ocurrences = this.toasts[foundIndex].ocurrences
            if (foundIndex !== -1 && ocurrences > 1) {
                this.toasts[foundIndex].ocurrences--
            }
            if (foundIndex !== -1 && ocurrences <= 1) {
                this.toasts.splice(foundIndex, 1)
            }
        }
    }
})
