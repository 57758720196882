import { defineStore } from 'pinia'
import 'shepherd.js/dist/css/shepherd.css';
import '../../assets/css/onboarding.css'
import { useShepherd } from "vue-shepherd";
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";
import { usePopsDiscountsStore } from "~/store/pops/discounts";
import { useTemplatesStore } from "~/store/templates";
import { useUpsellsAddToCartDiscountsStore } from "~/store/upsells/add_to_cart/discounts";
import { useSiteStore } from "~/store/site";

export const useNewCustomerOnboardingStore = defineStore({
    id: 'new_customer',
    state() {
        return {
            showStepFlag: false,
            onboarding: useShepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shadow-md bg-purple-dark',
                    scrollTo: true
                }
            }),
            steps: [
                {
                    id: 'start',
                    text: '<p class="onboarding-text">There you can see events with your Pops!</p>',
                    attachTo: {
                        element: "#popsEventsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsEventsSidebarButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsEventsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                await $nuxt.$router.push('/pops/events/');
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'show_pops_events',
                    text: '<p class="onboarding-text">You can see events with your pops!</p>',
                    attachTo: {
                        element: ".popsEventsPopContentSection",
                        on: 'top'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '.popsEventsPopContentSection';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_rules_and_settings',
                    text: '<p class="onboarding-text">Display active discounts while customers are browsing the store. With Discounts by POP! you can create a sense of urgency turning casual browsers into shoppers. The code will be copied to the clipboard when a customer clicks on the notification.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const menuButton = document.getElementById("popsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton");
                                menuButton.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_general_settings',
                    text: '<p class="onboarding-text">In General settings, you can customize placement, change the language, modify the timing between the pops, and more.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartGeneralMenuButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartGeneralMenuButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartGeneralMenuButton';

                            return new Promise((res) => {
                                if (useNewCustomerOnboardingStore().showStepFlag) {
                                    res();
                                    useNewCustomerOnboardingStore().showStepFlag = false;
                                }
                            }).then(async() => {
                                await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                    resolve();
                                })
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const button = document.getElementById("popsRulesAndSettingAddToCartGeneralMenuButton");
                                button.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_change_general_all_menu',
                    text: '<p class="onboarding-text">In General settings, you can customize placement, change the language, modify the timing between the pops, and more. Let\'s change language for example</p>',
                    attachTo: {
                        element: ".popsRulesAndSettingsGeneralAllMenu",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '.popsRulesAndSettingsGeneralAllMenu';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_change_general_all_cards',
                    text: '<p class="onboarding-text">In General settings, you can customize placement, change the language, modify the timing between the pops, and more. Let\'s change language for example</p>',
                    attachTo: {
                        element: ".popsRulesAndSettingsGeneralAllCards",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '.popsRulesAndSettingsGeneralAllCards';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_save_general_settings',
                    text: '<p class="onboarding-text">Don\'t forget save changes.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingsGeneralMenuSave",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingsGeneralMenuSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const saveButton = document.getElementById("popsRulesAndSettingsGeneralMenuSave");
                                if (!useSiteStore().loading) {
                                    saveButton.click();
                                }
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_general_settings_back',
                    text: '<p class="onboarding-text">Go back to Pops Rules & Settings.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingsGeneralMenuBack",
                        on: 'right'
                    },
                    advanceOn: {
                        selector: "#popsRulesAndSettingsGeneralMenuBack",
                        event: 'click',
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingsGeneralMenuBack';

                            return new Promise((res) => {
                                if (!useSiteStore().loading) {
                                    res();
                                }
                            }).then(async() => {
                                await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                    resolve();
                                })
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const button = document.getElementById("popsRulesAndSettingsGeneralMenuBack");
                                button.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_menu_discounts_button',
                    text: '<p class="onboarding-text">Choose the Discounts in Pop Rules & Settings to modify yor discount.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartDiscountMenuButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartDiscountMenuButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartDiscountMenuButton';

                            return new Promise((res) => {
                                if (useNewCustomerOnboardingStore().showStepFlag) {
                                    res();
                                    useNewCustomerOnboardingStore().showStepFlag = false;
                                }
                            }).then(async() => {
                                await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                    resolve();
                                })
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const menuButton = document.getElementById("popsRulesAndSettingAddToCartDiscountMenuButton");
                                menuButton.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_edit_rules_and_design_button',
                    text: '<p class="onboarding-text">Click on the button or choose Continue to go to editing discount page.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingAddToCartEditRulesAndDesignButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingAddToCartEditRulesAndDesignButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingAddToCartEditRulesAndDesignButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const button = document.getElementById("popsRulesAndSettingAddToCartEditRulesAndDesignButton");
                                button.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_new_discount_selector',
                    text: '<p class="onboarding-text">Search and import discounts from your Shopify Admin.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingSelectShopifyDiscount',
                        on: 'left'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingSelectShopifyDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog")
                                if(!closeModal){
                                    resolve();
                                }
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const selector = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelector");
                                selector.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_search_discount',
                    text: `<p class="onboarding-text-horizontal-step">Search your discounts from Shopify Admin.
                           Discounts that match our requirements are highlighted in purple, those that are highlighted in grey cannot be selected.</p>`,
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog',
                        on: 'top'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    beforeShowPromise: async() => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog';
                            const discountStore = usePopsDiscountsStore();
                            discountStore.shopifyDiscounts = [];

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(async () => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () =>{
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_choose_discount',
                    text: '<p class="onboarding-text">Choose your discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogDiscounts',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogAvailableDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () => {
                                const discountStore = usePopsDiscountsStore();
                                discountStore.shopifyDiscounts = [];

                                useNewCustomerOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_submit_discount',
                    text: '<p class="onboarding-text">Save your chosen discount.</p>',
                    attachTo: {
                        element: '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: ".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit",
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const submitButton = document.querySelector(".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit");
                                submitButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'design_in_sidebar_menu',
                    text: '<p class="onboarding-text">In this section, you can customize the message to match your brand’s voice.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingDesignMenuButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsRulesAndSettingDesignMenuButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().skipDiscountSteps = false;
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingDesignMenuButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const element = document.getElementById("popsRulesAndSettingDesignMenuButton");
                                const link = element.querySelector('a');
                                link.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'background_color_change',
                    text: '<p class="onboarding-text">Change the background color for your discount.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingChangeBackgroundColor',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingChangeBackgroundColor';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_background',
                    text: '<p class="onboarding-text">Your new background color.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingsPopModel',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingsPopModel';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_background',
                    text: '<p class="onboarding-text">Save your changed background for discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSettingAddToCartSettingsSave",
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                saveButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'theme_in_sidebar_menu',
                    text: '<p class="onboarding-text">You can choose the theme of your notification to make it stand out and grab your visitor’s attention.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingThemesMenuButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingThemesMenuButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        selector: "#popsRulesAndSettingThemesMenuButton",
                        event: 'click',
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const element = document.getElementById("popsRulesAndSettingThemesMenuButton");
                                const link = element.querySelector('a');
                                link.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'theme_selector',
                    text: '<p class="onboarding-text">Change the theme of your discount.</p>',
                    attachTo: {
                        element: '.popsRulesAndSettingsThemesSelector',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.popsRulesAndSettingsThemesSelector';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_theme',
                    text: '<p class="onboarding-text">Your new theme.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingsPopModel',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingsPopModel';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_theme',
                    text: '<p class="onboarding-text">Save your changed theme.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                if(!useTemplatesStore().loading) {
                                    const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                    saveButton.click();
                                }
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'button_back_to_pops_settings',
                    text: '<p class="onboarding-text">Go back to main Pops Rules & Settings menu.</p>',
                    attachTo: {
                        element: '#popsRulesAndSettingGoBackMenuButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#popsRulesAndSettingGoBackMenuButton';

                                return new Promise((res) => {
                                    if (Object.keys(useTemplatesStore().currentTemplateDifference)?.length === 0 && !useTemplatesStore().loading) {
                                        res();
                                    }
                                }).then(async() => {
                                    await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                        resolve();
                                    })
                            });
                        });
                    },
                    //
                    // advanceOn: {
                    //     event: 'click',
                    //     selector: "#popsRulesAndSettingGoBackMenuButton",
                    // },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const element = document.getElementById("popsRulesAndSettingGoBackMenuButton");
                                const link = element.querySelector('a');
                                link.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_edit_sidebar_button',
                    text: `<p class="onboarding-text">Add to Cart Upsells by POP! allow you to sync discounts created in Shopify and easily increase the average order value.</p>
                           <p class="onboarding-text">Visit the Help Center for <a class="onboarding-link" href="https://help.salespop.com/en/articles/8451440-guide-to-creating-add-to-cart-upsells" target="_blank">detailed instructions</a>.</p>`,
                    attachTo: {
                        element: "#upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const button = document.getElementById("upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton");
                                button.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding()
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'upsells_edit_rules_and_design_button',
                    text: '<p class="onboarding-text">Click on the button or choose Continue to go to editing discount page.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/discounts');
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'upsells_new_discount_selector',
                    text: '<p class="onboarding-text">Search and import discounts from your Shopify Admin.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscount',
                        on: 'left'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog")
                                if(!closeModal){
                                    resolve();
                                }
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const selector = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelector")
                                selector.click()
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_search_discount',
                    text: `<p class="onboarding-text-horizontal-step">Search your discounts from Shopify Admin.
                           Discounts that match our requirements are highlighted in purple, those that are highlighted in grey cannot be selected.</p>`,
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog',
                        on: 'top'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    beforeShowPromise: async() => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog';
                            const discountStore = useUpsellsAddToCartDiscountsStore();
                            discountStore.shopifyDiscounts = [];

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(async () => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () =>{
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_choose_discount',
                    text: '<p class="onboarding-text">Choose your discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogDiscounts',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogAvailableDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [

                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () => {
                                const discountStore = useUpsellsAddToCartDiscountsStore();
                                discountStore.shopifyDiscounts = [];

                                useNewCustomerOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useNewCustomerOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_submit_discount',
                    text: '<p class="onboarding-text">Save your chosen discount.</p>',
                    attachTo: {
                        element: '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: ".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit",
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const submitButton = document.querySelector(".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit");
                                submitButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_settings_go_back',
                    text: '<p class="onboarding-text">Go back to main menu.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSSettingsAddToCartGoBackButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().skipDiscountSteps = false;
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSSettingsAddToCartGoBackButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSSettingsAddToCartGoBackButton",
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const element = document.getElementById("upsellsRulesAndSSettingsAddToCartGoBackButton");
                                const link = element.querySelector('a');
                                link.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'pops_menu_metrics',
                    text: '<p class="onboarding-text">Track conversions, clicks, views, or best-performing popups in one place. You can select the time range here.</p>',
                    attachTo: {
                        element: "#popsRulesAndSettingAddToCartMetricsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsRulesAndSettingAddToCartMetricsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                const menuButton = document.getElementById("popsRulesAndSettingAddToCartMetricsSidebarButton");
                                menuButton.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'pops_metrics_selector',
                    text: '<p class="onboarding-text">You can select the time range here.</p>',
                    attachTo: {
                        element: "#popsMetricsHeaderSelector",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#popsMetricsHeaderSelector",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsMetricsHeaderSelector';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                const selector = document.getElementById("popsMetricsHeaderSelector");
                                selector.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'pops_metrics_calendar',
                    text: '<p class="onboarding-text">You can select the time range here. Choose the date and click `OK` button</p>',
                    attachTo: {
                        element: "#popsMetricsHeaderSelectorCalendar",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#popsMetricsHeaderSelectorCalendar';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            action: async () => {},
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'plans_and_pricing',
                    text: '<p class="onboarding-text">Here you can choose the option that is most suitable for your business.</p>',
                    attachTo: {
                        element: "#helpAccountPlansAndPricingSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#helpAccountPlansAndPricingSidebarButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAccountPlansAndPricingSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                const menuButton = document.getElementById("helpAccountPlansAndPricingSidebarButton");
                                menuButton.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'plans_section',
                    text: '<p class="onboarding-text">Here you can choose the option that is most suitable for your business. Lock a specific plan or we will assign one based on usage. You can decide on this later! Enjoy your 7-day free trial.</p>',
                    attachTo: {
                        element: "#helpAndAccountPlansAndPricingPlansSection",
                        on: 'top'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAndAccountPlansAndPricingPlansSection';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'auto_upgrade_section',
                    text: '<p class="onboarding-text">Auto-Upgrade allows us to continue showing the pops in your store, even if the limit of the current plan is reached. When enabled, the account is moved to the next applicable tier.</p>',
                    attachTo: {
                        element: "#helpAndAccountPlansAndPricingAutoUpgrade",
                        on: 'top'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            resolve();
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAndAccountPlansAndPricingAutoUpgrade';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'session_recorder',
                    text: '<p class="onboarding-text">This shows the sessions recorded so far. These will be reset every month.</p>',
                    attachTo: {
                        element: "#helpAndAccountPlansAndPricingSession",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep:  useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAndAccountPlansAndPricingSession';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'discount_input',
                    text: `<p class="onboarding-text">Wow, you made it this far! As a reward, here’s a 10% discount for your first two months.</p>
                           <p> Use the code: <span id="onboardingDiscountCode">POP-NEW24</span> in this section.</p>`,
                    attachTo: {
                        element: "#helpAndAccountPlansAndPricingPastCoupon",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    showOn: () => {
                        if (useSiteStore().currentSite.discounted) {
                            return false;
                        }
                        return true;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAndAccountPlansAndPricingPastCoupon';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Copy code',
                            action: async () => {
                                const codeElement = document.getElementById('onboardingDiscountCode');
                                const code = codeElement.innerText;

                                try {
                                    const textarea = document.createElement('textarea');
                                    textarea.value = code;
                                    document.body.appendChild(textarea);
                                    textarea.select();
                                    document.execCommand('copy');
                                    document.body.removeChild(textarea);

                                    console.log('Code copied to clipboard');
                                } catch (error) {
                                    console.error('Failed to copy code to clipboard:', error);
                                }
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Continue',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button',
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'discount_redeem',
                    text: `<p class="onboarding-text">Implement your discount.</p>
                           <p>If you need assistance, visit our Help Center or contact support via chat. You can also book a demo call in English or Spanish.</p>`,
                    attachTo: {
                        element: "#helpAndAccountPlansAndPricingCouponRedeem",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#helpAndAccountPlansAndPricingCouponRedeem",
                        event: 'click',
                    },
                    showOn: () => {
                        if (useSiteStore().currentSite.discounted) {
                            return false;
                        }
                        return true;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#helpAndAccountPlansAndPricingCouponRedeem';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async () => {
                                const button = document.getElementById("helpAndAccountPlansAndPricingCouponRedeem");
                                button.click();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Previous',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.back();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useNewCustomerOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ]
                },
                {
                    id: 'completed',
                    text: `
                        <div class="onboarding-container">
                            <h1 class="onboarding-title">Congratulations on completing onboarding!🎉🎉🎉</h1>
                            <p class="onboarding-text">
                            You have successfully completed all the steps. Congratulations!</p>
                        </div>`,
                    classes: 'custom-start-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useNewCustomerOnboardingStore().onboarding.getCurrentStep().id, reloadOnboardings: true });
                            useBaseOnboardingStore().completedOnboarding = true;
                            useBaseOnboardingStore().currentOnboarding = null;
                            resolve();
                        });
                    },
                    buttons: [
                        {
                            text: 'Finish onboardings',
                            classes: 'custom-button',
                            action: async () => {
                                useNewCustomerOnboardingStore().onboarding.complete();
                                await $nuxt.$router.push('/dashboard');
                            }
                        },
                    ],
                },
            ],
        }
    },
    actions: {
        addStepsToOnboarding(onboarding, steps){
            steps.forEach((step, index) => {
                step.when = {
                    show: () => {
                        const currentStepElement = onboarding.currentStep.el;
                        const horizontalStep = currentStepElement.querySelector('.onboarding-text-horizontal-step')
                        const stepFooter = currentStepElement.querySelector('.shepherd-footer');

                        if (horizontalStep) {
                            stepFooter.classList.add('onboarding-horizontal-footer');
                        } else {
                            stepFooter.classList.add('custom-footer');
                        }
                    },
                };
                onboarding.addStep(step);
            });
        },
    },
})
