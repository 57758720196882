import { defineStore } from 'pinia'
import Qs from "qs";
import 'shepherd.js/dist/css/shepherd.css';
import '../../assets/css/onboarding.css'
import { useDiscountsOnboardingStore } from "~/store/onboardings/pops/discounts";
import { useAddToCartOnboardingStore } from "~/store/onboardings/upsells/addToCart";
import { useNewCustomerOnboardingStore } from "~/store/onboardings/newCustomer";

export const useBaseOnboardingStore = defineStore({
    id: 'base_onboardings',
    state() {
        return {
            onboardingId: null,
            completedOnboarding: false,
            shopOnboardings: [],
            currentOnboarding: null,
            nextTimeOnboarding: false,
            skipDiscountSteps: false,
        }
    },
    actions: {
        async loadShopOnboardings() {
            const params = {
                sort: { type: "ASC" }
            }
            try {
              const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/onboardings`,
                    {
                        params,
                        paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
                    }
                );

                this.shopOnboardings = response.data;
            } catch (error) {
                console.log(error)
                return [];
            }
        },
        addStepsToOnboarding(onboarding, steps){
            steps.forEach((step, index) => {
                step.when = {
                    show: () => {
                        const currentStepElement = onboarding.currentStep.el;
                        const stepFooter = currentStepElement.querySelector('.shepherd-footer');
                        const progressWrapper = document.createElement('div');
                        const horizontalStep = currentStepElement.querySelector('.onboarding-text-horizontal-step')

                        if (horizontalStep) {
                            stepFooter.classList.add('onboarding-horizontal-footer');
                            progressWrapper.classList.add('onboarding-horizontal-footer-steps-wrapper');
                        } else {
                            stepFooter.classList.add('custom-footer');
                        }

                        const progress = document.createElement('span');
                        if (index !== onboarding.steps.length - 1) {
                            progress.classList.add('custom-steps');
                            progress.innerText = `Step ${onboarding.steps.indexOf(onboarding.currentStep) + 1} of ${onboarding.steps.length - 1}`;
                            progressWrapper.appendChild(progress);
                            stepFooter.appendChild(progressWrapper);
                        }
                    },
                };
                onboarding.addStep(step);
            });
        },
        showOnboardingStep(stepId){
            if (this.currentOnboarding?.isActive()){
                this.currentOnboarding?.show(stepId)
            }
        },
        waitForOnboardingElement(selector){
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        observer.disconnect();
                        resolve(document.querySelector(selector));
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                });
            })
        },
        async saveCurrentOnboardingStep({ currentStep, reloadOnboardings = false }){
            try{
                const response = await this.$nuxt.$axios.$patch(
                    `${process.env.API_V1_URL}/onboardings/${this.onboardingId}`,
                    {
                        onboarding: { current_step: currentStep }
                });

                if (reloadOnboardings) {
                    await this.loadShopOnboardings();
                }

                return response;
            } catch(error) {
                console.log(error);
            }
        },
        async skipOnboarding(){
            try{
                await this.$nuxt.$axios.$patch(`${process.env.API_V1_URL}/onboardings/${this.onboardingId}/skip`);
            } catch(error) {
                console.log(error);
            }
            this.currentOnboarding = null;
            this.onboardingId = null;
        },
        startOnboarding(onboarding){
            if (onboarding.type === "add_to_cart_upsells") {
                useAddToCartOnboardingStore().onboarding.steps = [];
                this.currentOnboarding = useAddToCartOnboardingStore().onboarding;
                this.addStepsToOnboarding(this.currentOnboarding, useAddToCartOnboardingStore().steps)
            }
            else if (onboarding.type === "discount_pops") {
                useDiscountsOnboardingStore().onboarding.steps = [];
                this.currentOnboarding = useDiscountsOnboardingStore().onboarding;
                this.addStepsToOnboarding(this.currentOnboarding, useDiscountsOnboardingStore().steps)
            }
            else if (onboarding.type === "new_customer") {
                useNewCustomerOnboardingStore().onboarding.steps = [];
                this.currentOnboarding = useNewCustomerOnboardingStore().onboarding;
                useNewCustomerOnboardingStore().addStepsToOnboarding(this.currentOnboarding, useNewCustomerOnboardingStore().steps)
            }

            if (this.currentOnboarding) {
                this.onboardingId = onboarding.id;
                this.currentOnboarding.start();
            }
        },
    }
})
