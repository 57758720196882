// Beacon script loading function copied from
// https://secure.helpscout.net/settings/beacons/5f03a2fd-af34-46d1-bf3f-cd8b04160dae/embed/web
!function (e, t, n) {
  function a() {
    var e = t.getElementsByTagName("script")[0],
      n = t.createElement("script");
    n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e);
  }
  if (e.Beacon = n = function n(t, _n, a) {
    e.Beacon.readyQueue.push({
      method: t,
      options: _n,
      data: a
    });
  }, n.readyQueue = [], "complete" === t.readyState) return a();
  e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1);
}(window, document, window.Beacon || function () {});

// Custom HelpScout helper functions
export default (function (context, inject) {
  var helpScoutAskViaChat = function helpScoutAskViaChat() {
    window.Beacon('navigate', '/ask/chat');
    window.Beacon('open');
  };
  inject('helpScoutAskViaChat', helpScoutAskViaChat);
  var helpScoutAskViaEmail = function helpScoutAskViaEmail() {
    window.Beacon('navigate', '/ask/message');
    window.Beacon('open');
  };
  inject('helpScoutAskViaEmail', helpScoutAskViaEmail);
  var helpScoutMessage = function helpScoutMessage(_ref) {
    var subject = _ref.subject,
      text = _ref.text,
      _ref$chat = _ref.chat,
      chat = _ref$chat === void 0 ? true : _ref$chat;
    window.Beacon('prefill', {
      subject: subject,
      text: text
    });
    chat ? helpScoutAskViaChat() : helpScoutAskViaEmail();
  };
  inject('helpScoutMessage', helpScoutMessage);
  var helpScoutCleanAsk = function helpScoutCleanAsk() {
    window.Beacon('reset');
    helpScoutAsk();
  };
  inject('helpScoutCleanAsk', helpScoutCleanAsk);
});