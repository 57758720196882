import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export var BarChart = function BarChart() {
  return import('../../components/Charts/BarChart.vue' /* webpackChunkName: "components/bar-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DoughnutChart = function DoughnutChart() {
  return import('../../components/Charts/DoughnutChart.vue' /* webpackChunkName: "components/doughnut-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var HorizontalBarChart = function HorizontalBarChart() {
  return import('../../components/Charts/HorizontalBarChart.vue' /* webpackChunkName: "components/horizontal-bar-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Accordion = function Accordion() {
  return import('../../components/DataDisplay/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ErrorTag = function ErrorTag() {
  return import('../../components/DataDisplay/ErrorTag.vue' /* webpackChunkName: "components/error-tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GenericList = function GenericList() {
  return import('../../components/DataDisplay/GenericList.vue' /* webpackChunkName: "components/generic-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var IconBox = function IconBox() {
  return import('../../components/DataDisplay/IconBox.vue' /* webpackChunkName: "components/icon-box" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KpiCard = function KpiCard() {
  return import('../../components/DataDisplay/KpiCard.vue' /* webpackChunkName: "components/kpi-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NotificationBoard = function NotificationBoard() {
  return import('../../components/DataDisplay/NotificationBoard.vue' /* webpackChunkName: "components/notification-board" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductView = function ProductView() {
  return import('../../components/DataDisplay/ProductView.vue' /* webpackChunkName: "components/product-view" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProgressBar = function ProgressBar() {
  return import('../../components/DataDisplay/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Reason = function Reason() {
  return import('../../components/DataDisplay/Reason.vue' /* webpackChunkName: "components/reason" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Tag = function Tag() {
  return import('../../components/DataDisplay/Tag.vue' /* webpackChunkName: "components/tag" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Toast = function Toast() {
  return import('../../components/DataDisplay/Toast.vue' /* webpackChunkName: "components/toast" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AccordionSection = function AccordionSection() {
  return import('../../components/Editor/AccordionSection.vue' /* webpackChunkName: "components/accordion-section" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DiscountContentEditor = function DiscountContentEditor() {
  return import('../../components/Editor/DiscountContentEditor.vue' /* webpackChunkName: "components/discount-content-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditorDrawer = function EditorDrawer() {
  return import('../../components/Editor/EditorDrawer.vue' /* webpackChunkName: "components/editor-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditorNavbarMobile = function EditorNavbarMobile() {
  return import('../../components/Editor/EditorNavbar.mobile.vue' /* webpackChunkName: "components/editor-navbar-mobile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditorSidebar = function EditorSidebar() {
  return import('../../components/Editor/EditorSidebar.vue' /* webpackChunkName: "components/editor-sidebar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EditorSidebarButton = function EditorSidebarButton() {
  return import('../../components/Editor/EditorSidebarButton.vue' /* webpackChunkName: "components/editor-sidebar-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SaveOrCancelPopSettings = function SaveOrCancelPopSettings() {
  return import('../../components/Editor/SaveOrCancelPopSettings.vue' /* webpackChunkName: "components/save-or-cancel-pop-settings" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpsellAddToCartEditorNavbarMobile = function UpsellAddToCartEditorNavbarMobile() {
  return import('../../components/Editor/UpsellAddToCartEditorNavbarMobile.vue' /* webpackChunkName: "components/upsell-add-to-cart-editor-navbar-mobile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmptyChart = function EmptyChart() {
  return import('../../components/EmptyState/EmptyChart.vue' /* webpackChunkName: "components/empty-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmptyInvoices = function EmptyInvoices() {
  return import('../../components/EmptyState/EmptyInvoices.vue' /* webpackChunkName: "components/empty-invoices" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmptyMediaItem = function EmptyMediaItem() {
  return import('../../components/EmptyState/EmptyMediaItem.vue' /* webpackChunkName: "components/empty-media-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmptyMenuItem = function EmptyMenuItem() {
  return import('../../components/EmptyState/EmptyMenuItem.vue' /* webpackChunkName: "components/empty-menu-item" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmptyPopEvents = function EmptyPopEvents() {
  return import('../../components/EmptyState/EmptyPopEvents.vue' /* webpackChunkName: "components/empty-pop-events" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BigToggleToken = function BigToggleToken() {
  return import('../../components/DataInput/BigToggleToken.vue' /* webpackChunkName: "components/big-toggle-token" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BinarySwitch = function BinarySwitch() {
  return import('../../components/DataInput/BinarySwitch.vue' /* webpackChunkName: "components/binary-switch" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Button = function Button() {
  return import('../../components/DataInput/Button.vue' /* webpackChunkName: "components/button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ColorCheckBox = function ColorCheckBox() {
  return import('../../components/DataInput/ColorCheckBox.vue' /* webpackChunkName: "components/color-check-box" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EmojiPicker = function EmojiPicker() {
  return import('../../components/DataInput/EmojiPicker.vue' /* webpackChunkName: "components/emoji-picker" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var FilterInput = function FilterInput() {
  return import('../../components/DataInput/FilterInput.vue' /* webpackChunkName: "components/filter-input" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ImageUpload = function ImageUpload() {
  return import('../../components/DataInput/ImageUpload.vue' /* webpackChunkName: "components/image-upload" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProductSelect = function ProductSelect() {
  return import('../../components/DataInput/ProductSelect.vue' /* webpackChunkName: "components/product-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var RatingStars = function RatingStars() {
  return import('../../components/DataInput/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ShopifyDiscountSelect = function ShopifyDiscountSelect() {
  return import('../../components/DataInput/ShopifyDiscountSelect.vue' /* webpackChunkName: "components/shopify-discount-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SimpleSelect = function SimpleSelect() {
  return import('../../components/DataInput/SimpleSelect.vue' /* webpackChunkName: "components/simple-select" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TipTap = function TipTap() {
  return import('../../components/DataInput/TipTap.vue' /* webpackChunkName: "components/tip-tap" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TwoSideBinarySwitch = function TwoSideBinarySwitch() {
  return import('../../components/DataInput/TwoSideBinarySwitch.vue' /* webpackChunkName: "components/two-side-binary-switch" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AbandonedCartTimeIntervalSettings = function AbandonedCartTimeIntervalSettings() {
  return import('../../components/Forms/AbandonedCartTimeIntervalSettings.vue' /* webpackChunkName: "components/abandoned-cart-time-interval-settings" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddToCartDesignEditor = function AddToCartDesignEditor() {
  return import('../../components/Forms/AddToCartDesignEditor.vue' /* webpackChunkName: "components/add-to-cart-design-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddToCartTemplateEditor = function AddToCartTemplateEditor() {
  return import('../../components/Forms/AddToCartTemplateEditor.vue' /* webpackChunkName: "components/add-to-cart-template-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AnonymousPopsSettings = function AnonymousPopsSettings() {
  return import('../../components/Forms/AnonymousPopsSettings.vue' /* webpackChunkName: "components/anonymous-pops-settings" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BrandingForm = function BrandingForm() {
  return import('../../components/Forms/BrandingForm.vue' /* webpackChunkName: "components/branding-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChooseDevicesForm = function ChooseDevicesForm() {
  return import('../../components/Forms/ChooseDevicesForm.vue' /* webpackChunkName: "components/choose-devices-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChooseLanguageForm = function ChooseLanguageForm() {
  return import('../../components/Forms/ChooseLanguageForm.vue' /* webpackChunkName: "components/choose-language-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChoosePopPositionForm = function ChoosePopPositionForm() {
  return import('../../components/Forms/ChoosePopPositionForm.vue' /* webpackChunkName: "components/choose-pop-position-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ChoosePopSizeForm = function ChoosePopSizeForm() {
  return import('../../components/Forms/ChoosePopSizeForm.vue' /* webpackChunkName: "components/choose-pop-size-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CsvReviewsForm = function CsvReviewsForm() {
  return import('../../components/Forms/CsvReviewsForm.vue' /* webpackChunkName: "components/csv-reviews-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var JudgeMeForm = function JudgeMeForm() {
  return import('../../components/Forms/JudgeMeForm.vue' /* webpackChunkName: "components/judge-me-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LowInventoryQuantityThresholdForm = function LowInventoryQuantityThresholdForm() {
  return import('../../components/Forms/LowInventoryQuantityThresholdForm.vue' /* webpackChunkName: "components/low-inventory-quantity-threshold-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ManualReviewForm = function ManualReviewForm() {
  return import('../../components/Forms/ManualReviewForm.vue' /* webpackChunkName: "components/manual-review-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopActionEvents = function PopActionEvents() {
  return import('../../components/Forms/PopActionEvents.vue' /* webpackChunkName: "components/pop-action-events" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopBackgroundImageForm = function PopBackgroundImageForm() {
  return import('../../components/Forms/PopBackgroundImageForm.vue' /* webpackChunkName: "components/pop-background-image-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopBorderRadius = function PopBorderRadius() {
  return import('../../components/Forms/PopBorderRadius.vue' /* webpackChunkName: "components/pop-border-radius" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopColorsForm = function PopColorsForm() {
  return import('../../components/Forms/PopColorsForm.vue' /* webpackChunkName: "components/pop-colors-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopContent = function PopContent() {
  return import('../../components/Forms/PopContent.vue' /* webpackChunkName: "components/pop-content" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopMediaTemplates = function PopMediaTemplates() {
  return import('../../components/Forms/PopMediaTemplates.vue' /* webpackChunkName: "components/pop-media-templates" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopPagesFilterForm = function PopPagesFilterForm() {
  return import('../../components/Forms/PopPagesFilterForm.vue' /* webpackChunkName: "components/pop-pages-filter-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopTemplateEditor = function PopTemplateEditor() {
  return import('../../components/Forms/PopTemplateEditor.vue' /* webpackChunkName: "components/pop-template-editor" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopTimeSettings = function PopTimeSettings() {
  return import('../../components/Forms/PopTimeSettings.vue' /* webpackChunkName: "components/pop-time-settings" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopTimingForm = function PopTimingForm() {
  return import('../../components/Forms/PopTimingForm.vue' /* webpackChunkName: "components/pop-timing-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopType = function PopType() {
  return import('../../components/Forms/PopType.vue' /* webpackChunkName: "components/pop-type" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopUniqSessionsPerVisitorForm = function PopUniqSessionsPerVisitorForm() {
  return import('../../components/Forms/PopUniqSessionsPerVisitorForm.vue' /* webpackChunkName: "components/pop-uniq-sessions-per-visitor-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ReviewCapForm = function ReviewCapForm() {
  return import('../../components/Forms/ReviewCapForm.vue' /* webpackChunkName: "components/review-cap-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ReviewDisplayForm = function ReviewDisplayForm() {
  return import('../../components/Forms/ReviewDisplayForm.vue' /* webpackChunkName: "components/review-display-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SessionsCapForm = function SessionsCapForm() {
  return import('../../components/Forms/SessionsCapForm.vue' /* webpackChunkName: "components/sessions-cap-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SummaryDisplayForm = function SummaryDisplayForm() {
  return import('../../components/Forms/SummaryDisplayForm.vue' /* webpackChunkName: "components/summary-display-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var VisitorsSessionCapForm = function VisitorsSessionCapForm() {
  return import('../../components/Forms/VisitorsSessionCapForm.vue' /* webpackChunkName: "components/visitors-session-cap-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Actions = function Actions() {
  return import('../../components/Layout/Actions.vue' /* webpackChunkName: "components/actions" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Card = function Card() {
  return import('../../components/Layout/Card.vue' /* webpackChunkName: "components/card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Sidebar = function Sidebar() {
  return import('../../components/Layout/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SidebarButton = function SidebarButton() {
  return import('../../components/Layout/SidebarButton.vue' /* webpackChunkName: "components/sidebar-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SmartDialog = function SmartDialog() {
  return import('../../components/Layout/SmartDialog.vue' /* webpackChunkName: "components/smart-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Toasts = function Toasts() {
  return import('../../components/Layout/Toasts.vue' /* webpackChunkName: "components/toasts" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Toolbar = function Toolbar() {
  return import('../../components/Layout/Toolbar.vue' /* webpackChunkName: "components/toolbar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TopbarMobile = function TopbarMobile() {
  return import('../../components/Layout/TopbarMobile.vue' /* webpackChunkName: "components/topbar-mobile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AvailableUpsellsList = function AvailableUpsellsList() {
  return import('../../components/Upsells/availableUpsellsList.vue' /* webpackChunkName: "components/available-upsells-list" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var BillingStatusNotification = function BillingStatusNotification() {
  return import('../../components/business/BillingStatusNotification.vue' /* webpackChunkName: "components/billing-status-notification" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DaysRemainingAnnualPlanCTA = function DaysRemainingAnnualPlanCTA() {
  return import('../../components/business/DaysRemainingAnnualPlanCTA.vue' /* webpackChunkName: "components/days-remaining-annual-plan-c-t-a" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DaysRemainingFreePlanCTA = function DaysRemainingFreePlanCTA() {
  return import('../../components/business/DaysRemainingFreePlanCTA.vue' /* webpackChunkName: "components/days-remaining-free-plan-c-t-a" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DesignHuddleModal = function DesignHuddleModal() {
  return import('../../components/business/DesignHuddleModal.vue' /* webpackChunkName: "components/design-huddle-modal" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DowngradePricingPlanAtNotification = function DowngradePricingPlanAtNotification() {
  return import('../../components/business/DowngradePricingPlanAtNotification.vue' /* webpackChunkName: "components/downgrade-pricing-plan-at-notification" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var EventsTable = function EventsTable() {
  return import('../../components/business/EventsTable.vue' /* webpackChunkName: "components/events-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var InvoicesTable = function InvoicesTable() {
  return import('../../components/business/InvoicesTable.vue' /* webpackChunkName: "components/invoices-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var JustEmail = function JustEmail() {
  return import('../../components/business/JustEmail.vue' /* webpackChunkName: "components/just-email" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OnePopEvent = function OnePopEvent() {
  return import('../../components/business/OnePopEvent.vue' /* webpackChunkName: "components/one-pop-event" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PerksGrid = function PerksGrid() {
  return import('../../components/business/PerksGrid.vue' /* webpackChunkName: "components/perks-grid" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PlanChangeModal = function PlanChangeModal() {
  return import('../../components/business/PlanChangeModal.vue' /* webpackChunkName: "components/plan-change-modal" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PlanLimits = function PlanLimits() {
  return import('../../components/business/PlanLimits.vue' /* webpackChunkName: "components/plan-limits" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopPreview = function PopPreview() {
  return import('../../components/business/PopPreview.vue' /* webpackChunkName: "components/pop-preview" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopResponsivePreview = function PopResponsivePreview() {
  return import('../../components/business/PopResponsivePreview.vue' /* webpackChunkName: "components/pop-responsive-preview" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopTemplateToggle = function PopTemplateToggle() {
  return import('../../components/business/PopTemplateToggle.vue' /* webpackChunkName: "components/pop-template-toggle" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopThemeCategoryCard = function PopThemeCategoryCard() {
  return import('../../components/business/PopThemeCategoryCard.vue' /* webpackChunkName: "components/pop-theme-category-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopTypeChooser = function PopTypeChooser() {
  return import('../../components/business/PopTypeChooser.vue' /* webpackChunkName: "components/pop-type-chooser" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProgressCard = function ProgressCard() {
  return import('../../components/business/ProgressCard.vue' /* webpackChunkName: "components/progress-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ResponsiveToggle = function ResponsiveToggle() {
  return import('../../components/business/ResponsiveToggle.vue' /* webpackChunkName: "components/responsive-toggle" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ReviewCard = function ReviewCard() {
  return import('../../components/business/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SessionsLimitReachedCTA = function SessionsLimitReachedCTA() {
  return import('../../components/business/SessionsLimitReachedCTA.vue' /* webpackChunkName: "components/sessions-limit-reached-c-t-a" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SmartrMail = function SmartrMail() {
  return import('../../components/business/SmartrMail.vue' /* webpackChunkName: "components/smartr-mail" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpgradeOverlay = function UpgradeOverlay() {
  return import('../../components/business/UpgradeOverlay.vue' /* webpackChunkName: "components/upgrade-overlay" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpsellAddToCartResponsivePreview = function UpsellAddToCartResponsivePreview() {
  return import('../../components/business/UpsellAddToCartResponsivePreview.vue' /* webpackChunkName: "components/upsell-add-to-cart-responsive-preview" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpsellsSiteEventsTable = function UpsellsSiteEventsTable() {
  return import('../../components/business/UpsellsSiteEventsTable.vue' /* webpackChunkName: "components/upsells-site-events-table" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Drawer = function Drawer() {
  return import('../../components/Layout/Drawer/Drawer.vue' /* webpackChunkName: "components/drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DrawerButton = function DrawerButton() {
  return import('../../components/Layout/Drawer/DrawerButton.vue' /* webpackChunkName: "components/drawer-button" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var GenericDrawer = function GenericDrawer() {
  return import('../../components/Layout/Drawer/GenericDrawer.vue' /* webpackChunkName: "components/generic-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsActionsCountCards = function PopsMetricsActionsCountCards() {
  return import('../../components/Pops/Metrics/PopsMetricsActionsCountCards.vue' /* webpackChunkName: "components/pops-metrics-actions-count-cards" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsConversionAggregationsChart = function PopsMetricsConversionAggregationsChart() {
  return import('../../components/Pops/Metrics/PopsMetricsConversionAggregationsChart.vue' /* webpackChunkName: "components/pops-metrics-conversion-aggregations-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsConversionByPopTypesAggregationsChart = function PopsMetricsConversionByPopTypesAggregationsChart() {
  return import('../../components/Pops/Metrics/PopsMetricsConversionByPopTypesAggregationsChart.vue' /* webpackChunkName: "components/pops-metrics-conversion-by-pop-types-aggregations-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsDiscountCodeAggregationsChart = function PopsMetricsDiscountCodeAggregationsChart() {
  return import('../../components/Pops/Metrics/PopsMetricsDiscountCodeAggregationsChart.vue' /* webpackChunkName: "components/pops-metrics-discount-code-aggregations-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsTemplatesActionsBarChart = function PopsMetricsTemplatesActionsBarChart() {
  return import('../../components/Pops/Metrics/PopsMetricsTemplatesActionsBarChart.vue' /* webpackChunkName: "components/pops-metrics-templates-actions-bar-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PopsMetricsTemplatesActionsPieChart = function PopsMetricsTemplatesActionsPieChart() {
  return import('../../components/Pops/Metrics/PopsMetricsTemplatesActionsPieChart.vue' /* webpackChunkName: "components/pops-metrics-templates-actions-pie-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpsellsMetricsActionsCountCards = function UpsellsMetricsActionsCountCards() {
  return import('../../components/Upsells/Metrics/UpsellsMetricsActionsCountCards.vue' /* webpackChunkName: "components/upsells-metrics-actions-count-cards" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var UpsellsMetricsConversionAggregationsChart = function UpsellsMetricsConversionAggregationsChart() {
  return import('../../components/Upsells/Metrics/UpsellsMetricsConversionAggregationsChart.vue' /* webpackChunkName: "components/upsells-metrics-conversion-aggregations-chart" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}