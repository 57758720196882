/*
** TailwindCSS Configuration File
**
** Docs: https://tailwindcss.com/docs/configuration
** Default: https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
*/
module.exports = {
  exposeConfig: true,
  theme: {
    extend: {
      screens: {
        mobile: { min: '240px', max: '949px' },
        // Mobile includes phone and tablets
        desktop: '950px'
      },
      boxShadow: {
        md: '0px 3px 10px rgba(0, 0, 0, 0.1)'
      },
      borderRadius: {
        lg: '12px',
        xl: '1.6rem'
      },
      opacity:{
        '10': '0.1',
        '90': '0.9',
      },
      colors: {

        base: {
          300: '#F2F6FA',
        },
        primary:{
          900: '#060542',
          800: '#161466',
          500: '#6F58FF',
          300: '#a091ff',
          a10: '#6F58FF1A',
          a20: '#6F58FF33',
        },
        neutral: {
          300: '#EEF2F7',
          400: '#BBC6D4',
          500: '#C4C9D1',
          600: '#8795A1',
          700: '#545c63'
        },
        green: '#13BABA',
        yellow: '#F89815',
        lightyellow: '#f8bf15',
        error: '#FF7675',

        // Support Cartkit UI
        foreground: {
          500: '#DCE6EE',
          600: '#8795A1',
          label: '#060542',
        },
        background: {
          hover: '#F2F6FA',
          neutral: '#fff',
        },
      }
    },
  },
  variants: {},
  plugins: [
    require('tailwind-css-variables')()
  ],
  purge: {
    // Learn more on https://tailwindcss.com/docs/controlling-file-size/#removing-unused-css
    enabled: process.env.NODE_ENV === 'production',
    content: [
      'components/**/*.vue',
      'layouts/**/*.vue',
      'pages/**/*.vue',
      'plugins/**/*.js',
      'nuxt.config.js'
    ],
    whitelist: ['aspect-ratio-square']
  }
}
