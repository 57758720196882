import { defineStore } from "pinia";
import { useShepherd } from "vue-shepherd";
import 'shepherd.js/dist/css/shepherd.css';
import '../../../assets/css/onboarding.css'
import { useUpsellsAddToCartDiscountsStore } from "~/store/upsells/add_to_cart/discounts";
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";
import { useSiteStore } from "~/store/site";

export const useAddToCartOnboardingStore = defineStore({
    id: 'add_to_cart_upsells',
    state: () => {
        return {
            onboarding: useShepherd({
                useModalOverlay: true,
                defaultStepOptions: {
                    classes: 'shadow-md bg-purple-dark',
                    scrollTo: true
                }
            }),
            steps: [
                {
                    id: 'start',
                    text: '<p class="onboarding-text">Add to Cart Upsells by POP! allow you to sync discounts created in Shopify and easily increase the average order value.</p>',
                    attachTo: {
                        element: "#upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#upsellsRulesAndSettingAddToCartEditRulesAndSettingsSidebarButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            action: async ()=>{
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/')
                                useAddToCartOnboardingStore().onboarding.next();
                            },
                            classes: 'custom-button'
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding()
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'binary_switch_button',
                    text: '<p class="onboarding-text">Click on the toggle to activate Upsell Add to cart.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartBinarySwitch',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartBinarySwitch",
                        event: 'click',
                    },
                    showOn: () => {
                        if (useSiteStore().currentSite.force_auth_scopes_reauth) {
                            return true;
                        }
                        return false;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartBinarySwitch';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const binarySwitch = document.getElementById("upsellsRulesAndSettingAddToCartBinarySwitch")
                                binarySwitch.click()
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'reauth_smart_dialog',
                    text: `<p class="onboarding-text">Click to authorize permissions required for Upsell Add to cart.</p>`,
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton',
                        on: 'top'
                    },
                    classes: 'custom-content',
                    showOn: () => {
                        if (useSiteStore().currentSite.force_auth_scopes_reauth) {
                            return true;
                        }
                        return false;
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const reauthButton = document.getElementById("upsellsRulesAndSettingAddToCartForceReauthSmartDialogReauthButton")
                                reauthButton.click()
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'edit_rules_and_design_button',
                    text: '<p class="onboarding-text">Click on the button or choose Continue to go to editing discount page.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartEditRulesAndDesignButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/discounts');
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                            classes: 'close-button position-close-button'
                        },
                    ],
                },
                {
                    id: 'upsells_new_discount_selector',
                    text: '<p class="onboarding-text">Search and import discounts from your Shopify Admin.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscount',
                        on: 'left'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog")
                                if(!closeModal){
                                    resolve();
                                }
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const selector = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelector")
                                selector.click()
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_search_discount',
                    text: `<p class="onboarding-text-horizontal-step">Search your discounts from Shopify Admin.
                          Discounts that match our requirements are highlighted in purple, those that are highlighted in grey cannot be selected.</p>`,
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog',
                        on: 'top'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    beforeShowPromise: async() => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });

                            const selector = '#upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialog';
                            const discountStore = useUpsellsAddToCartDiscountsStore();
                            discountStore.shopifyDiscounts = [];

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(async () => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () =>{
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_choose_discount',
                    text: '<p class="onboarding-text">Choose your discount.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogDiscounts',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.upsellsRulesAndSettingAddToCartDiscountsSelectDiscountSmartDialogAvailableDiscount';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [

                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: () => {
                                const discountStore = useUpsellsAddToCartDiscountsStore();
                                discountStore.shopifyDiscounts = [];

                                useAddToCartOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip',
                            classes: 'custom-button',
                            action: async () => {
                                useBaseOnboardingStore().skipDiscountSteps = true;
                                const closeModal = document.getElementById("upsellsRulesAndSettingAddToCartDiscountsSelectShopifyDiscountSmartDialogCloseButton");
                                closeModal.click();
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'upsells_submit_discount',
                    text: '<p class="onboarding-text">Save your chosen discount.</p>',
                    attachTo: {
                        element: '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit',
                        on: 'right'
                    },
                    showOn: () => {
                        return !useBaseOnboardingStore().skipDiscountSteps;
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: ".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit",
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const submitButton = document.querySelector(".newDiscountEditorSubmit #upsellsRulesAndSettingAddToCartDiscountsSelectShopifySelectorEditorSubmit");
                                submitButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'settings_in_add_to_cart_menu',
                    text: '<p class="onboarding-text">In this section, you can customize the message to match your brand’s voice.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsNavbarSettingsButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartSettingsNavbarSettingsButton",
                        event: 'click',
                    },
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().skipDiscountSteps = false;
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsNavbarSettingsButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/settings')
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'title_input',
                    text: '<p class="onboarding-text">Change the title for your popup.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsTitleChangeInput',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsTitleChangeInput';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_title',
                    text: '<p class="onboarding-text">Your new title.</p>',
                    attachTo: {
                        element: '.add-to-cart-title',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.add-to-cart-title';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_title',
                    text: '<p class="onboarding-text">Save your changed title for popup.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSettingAddToCartSettingsSave",
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                saveButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'design_in_add_to_cart_menu',
                    text: '<p class="onboarding-text">You can choose the color of your notification to make it stand out and grab your visitor’s attention. You can also select a seasonal design in the Themes tab.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDesignNavbarDesignButton',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDesignNavbarDesignButton';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        selector: "#upsellsRulesAndSettingAddToCartDesignNavbarDesignButton",
                        event: 'click',
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/design');
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'color_selector',
                    text: '<p class="onboarding-text">Change the color of the title to match your theme.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartDesignChangeTitleColor',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartDesignChangeTitleColor';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                await $nuxt.$router.push('/upsells/rules-and-settings/add-to-cart/design');
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'show_color',
                    text: '<p class="onboarding-text">Your new color.</p>',
                    attachTo: {
                        element: '.add-to-cart-title',
                        on: 'left'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '.add-to-cart-title';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    buttons: [
                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.next();
                            }
                        },
                        {
                            text: 'Previous',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.back();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'save_color',
                    text: '<p class="onboarding-text">Save your changed title color.</p>',
                    attachTo: {
                        element: '#upsellsRulesAndSettingAddToCartSettingsSave',
                        on: 'right'
                    },
                    classes: 'custom-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id });
                            const selector = '#upsellsRulesAndSettingAddToCartSettingsSave';

                            await useBaseOnboardingStore().waitForOnboardingElement(selector).then(() => {
                                resolve();
                            });
                        });
                    },
                    advanceOn: {
                        event: 'click',
                        selector: "#upsellsRulesAndSettingAddToCartSettingsSave",
                    },
                    buttons: [

                        {
                            text: 'Continue',
                            classes: 'custom-button',
                            action: async () => {
                                const saveButton = document.getElementById("upsellsRulesAndSettingAddToCartSettingsSave");
                                saveButton.click();
                            }
                        },
                        {
                            text: 'Skip Onboarding',
                            classes: 'close-button position-close-button',
                            action: () => {
                                useBaseOnboardingStore().skipOnboarding();
                                useAddToCartOnboardingStore().onboarding.cancel();
                            },
                        },
                    ],
                },
                {
                    id: 'completed',
                    text: `
                        <div class="onboarding-container">
                            <h1 class="onboarding-title">Congratulations on completing onboarding!🎉🎉🎉</h1>
                            <p class="onboarding-text">
                            You have successfully completed all the steps and now have the knowledge
                            of how to choose a discount and change the design of your popup.</p>
                        </div>`,
                    classes: 'custom-start-content',
                    beforeShowPromise: () => {
                        return new Promise(async (resolve) => {
                            useBaseOnboardingStore().saveCurrentOnboardingStep({ currentStep: useAddToCartOnboardingStore().onboarding.getCurrentStep().id, reloadOnboardings: true });
                            useBaseOnboardingStore().completedOnboarding = true;
                            useBaseOnboardingStore().currentOnboarding = null;

                            resolve();
                        });
                    },
                    buttons: [
                        {
                            text: 'Finish onboardings',
                            classes: 'custom-button',
                            action: async () => {
                                useAddToCartOnboardingStore().onboarding.complete();
                                await $nuxt.$router.push('/dashboard');
                            }
                        },
                    ],
                },
            ],
        }
    },
})
