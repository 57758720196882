import { useSiteStore } from "~/store/site";
export default (function (context, inject) {
  var siteStore = useSiteStore();
  inject('isPro', function () {
    if (!siteStore.currentSite.plan) return true;
    return siteStore.currentSite.plan === 'paid' || siteStore.currentSite.source === 'wix';
  });
  inject('source', function () {
    return siteStore.currentSite.source;
  });
});