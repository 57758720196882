import { defineStore } from 'pinia'
import Qs from "qs";
import MD5 from 'md5.js'
import numeral from 'numeral'

export const usePopMetricsStore = defineStore({
    id: 'popMetrics',
    state() {
        return {
            conversionAggregations: {
                aggregations: {},
                currencies: [],
                granularity: 'day',
            },
            loadingConversionAggregations: false,
            conversionsTotals: {
                total_amount_by_currency: {},
                total_count: 0
            },
            loadingConversionsTotals: false,
            overviewConversionsTotals: {
                total_amount_by_currency: {},
                total_count: 0
            },
            loadingOverviewConversionsTotals: false,
            conversionByTypeAggregations: {
                aggregations: {},
                pop_types: [],
                granularity: 'day'
            },
            loadingConversionByTypeAggregation: false,
            discountCodeAggregations: {
              aggregations: {},
              discount_codes: [],
              total_count: {},
              granularity: 'day'
            },
            loadingDiscountCodeAggregations: false,
            actionsCount: {
                aggregations: {
                    pop_click: 0,
                    pop_close: 0,
                    pop_hover: 0,
                    pop_view: 0,
                    site_pageview: 0,
                    site_session: 0,
                }
            },
            loadingActionsCount: false,
            templatesClicksAggregations: {
                aggregations: {},
                template_handles: [],
                granularity: 'day',
            },
            loadingTemplatesClicksAggregations: false,
            templatesViewsAggregations: {
                aggregations: {},
                template_handles: [],
                granularity: 'day',
            },
            loadingTemplatesViewsAggregations: false,
            granularityData: {
                dateFormats: {
                    hour: 'h A',
                    day: 'DD/MM/YYYY',
                    month: 'MM/YYYY',
                    year: 'YYYY',
                },
                texts: {
                    hour: 'Hourly',
                    day: 'Daily',
                    month: 'Monthly',
                    year: 'Yearly',
                }
            },
        }
    },
    getters: {
        requestDateFormat() {
            return 'YYYY-MM-DD'
        },
        conversionsTotalsStrings() {
            return _.map(this.conversionsTotals.total_amount_by_currency, (amount, currency) => {
                return this.totalAmountString(amount, currency)
            })
        },
        overviewConversionsTotalsStrings() {
            return _.map(this.overviewConversionsTotals.total_amount_by_currency, (amount, currency) => {
                return this.totalAmountString(amount, currency)
            })
        },
    },
    actions: {
        colorFromMD5(string) {
          return `#${new MD5().update(string).digest('hex').substring(0, 6)}`
        },
        totalAmountString(amount, currency) {
            return `${numeral(amount).format('0,0.00')} ${currency}`
        },
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
        },
        encodedURIDate(date) {
            return encodeURIComponent(date.format(this.requestDateFormat))
        },
        async loadConversionAggregations({ startDate, endDate }) {
            this.loadingConversionAggregations = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/conversions`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.conversionAggregations = response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load conversions data.',
                    e
                })
            } finally {
                this.loadingConversionAggregations = false
            }
        },
        async loadConversionsTotals({ startDate, endDate }) {
            this.loadingConversionsTotals = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }
                
                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/conversions`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.conversionsTotals.total_amount_by_currency = response.data.total_amount_by_currency
                this.conversionsTotals.total_count = response.data.total_count
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Failed to load conversions totals',
                    e
                })
            } finally {
                this.loadingConversionsTotals = false
            }
        },
        async loadOverviewConversionsTotals({ startDate, endDate }) {
            this.loadingOverviewConversionsTotals = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }
                
                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/conversions`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.overviewConversionsTotals.total_amount_by_currency = response.data.total_amount_by_currency
                this.overviewConversionsTotals.total_count = response.data.total_count
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Failed to load conversions totals',
                    e
                })
            } finally {
                this.loadingOverviewConversionsTotals = false
            }
        },
        async loadConversionByTypeAggregations({ startDate, endDate }) {
            this.loadingConversionByTypeAggregation = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/conversions_by_pop_types`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.conversionByTypeAggregations = response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load conversions data.',
                    e
                })
            } finally {
                this.loadingConversionByTypeAggregation = false
            }
        },
        async loadDiscountCodeAggregations({ startDate, endDate, action }){
            this.loadingDiscountCodeAggregations = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                        action: action
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/discount_codes`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.discountCodeAggregations = response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load discount codes data.',
                    e
                })
            } finally {
                this.loadingDiscountCodeAggregations = false
            }
        },
        async loadActionsCount({ startDate, endDate }) {
            this.loadingActionsCount = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/actions_count`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.actionsCount = response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load actions data.',
                    e
                })
            } finally {
                this.loadingActionsCount = false
            }
        },
        async loadTemplatesClicksAggregations({  startDate, endDate }) {
            if(this.loadingTemplatesClicksAggregations) return

            this.loadingTemplatesClicksAggregations = true

            this.templatesClicksAggregations = await this.loadTemplatesActionAggregations({
                startDate,
                endDate,
                action: 'pop_click'
            })

            this.loadingTemplatesClicksAggregations = false
        },
        async loadTemplatesViewsAggregations({  startDate, endDate }) {
            if(this.loadingTemplatesViewsAggregations) return

            this.loadingTemplatesViewsAggregations = true

            this.templatesViewsAggregations = await this.loadTemplatesActionAggregations({
                startDate,
                endDate,
                action: 'pop_view'
            })

            this.loadingTemplatesViewsAggregations = false
        },
        async loadTemplatesActionAggregations({  startDate, endDate, action }) {
            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                        action
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/metrics/aggregations/templates_action_count`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                return response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load templates actions data.',
                    e
                })
            }
        }
    }
})
