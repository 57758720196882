import { defineStore } from 'pinia'
import { omitBy, isUndefined } from 'lodash'
import Qs from 'qs'

export const useThemesStore = defineStore({
    id: 'themes',
    state(){
        return {
            themeGroups: [],
            currentTheme: {},
            themePackages: {},
        }
    },
    getters: {
        currentThemeWithProofs(){
            const { currentTheme } = this
            const themeWithProofs ={
                proof_background_color: currentTheme.background_color,
                proof_background_image_url: currentTheme.background_image_url,
                proof_border_radius: currentTheme.border_radius,
                proof_font_color: currentTheme.font_color
            }
            return omitBy(themeWithProofs, isUndefined)
        },
        currentThemeIsPro(){
          return this.currentTheme?.paid_only ?? false
        }
    },
    actions: {
      async loadThemeGroups() {
        try {
          const params = {
            sort: {
              paid_only: "DESC",
              id: "ASC"
            }
          };

          const response = await this.$nuxt.$axios.$get(
            `${process.env.API_V1_URL}/pops/theme_groups`,
            {
              params,
              paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
            },
          )

          this.themeGroups = response.data
        } catch (e) {
          this.$nuxt.$toasts.add({
            type: 'error',
            title: 'Something went wrong!',
            subtitle: 'Something went wrong and we could not load theme groups.',
            e
          })
          console.log(e)
        }
      },
      async loadThemes({ groupId }) {
        try {
          const params = {
            filter: {
              theme_group_id: groupId
            }
          };

          const response = await this.$nuxt.$axios.$get(
            `${process.env.API_V1_URL}/pops/themes`,
            {
              params,
              paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
            }
          )

          this.themePackages = {
            ...this.themePackages,
            [groupId]: response.data,
          };
        } catch (e) {
          this.$nuxt.$toasts.add({
            type: 'error',
            title: 'Something went wrong!',
            subtitle: 'Something went wrong and we could not load themes.',
            e
          })
          console.log(e)
        }
      },
    }
})
