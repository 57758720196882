import { clone, keys, transform, isEqual, isObject } from 'lodash'
import { defineStore } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useThemesStore } from '~/store/themes'
import { useIntegrationsStore } from '~/store/integrations'
import { useSiteStore } from './site'

export const useTemplatesStore = defineStore({
    id: 'templates',
    state(){
        return {
            templates: [],
            originalTemplates: [],
            currentTemplate: {},
            loading: false,
            invalidForms: []
        }
    },
    getters: {
        editingTemplate(){
            return this.templates.find(template =>
                template.handle === useUiStore().editingPopHandler,
            ) ?? {}
        },
        bodyObject() {
            const tags = this.currentTemplate?.body?.split('</top><middle>')
            if(!tags) return {}
            return {
                top: tags[0]?.replace('<top>', '') ?? '',
                middle: tags[1]?.replace('</middle>', '') ?? ''
            }
        },
        parsedBodyObject() {
            const integrationsStore = useIntegrationsStore()
            const parsedObject = clone(this.bodyObject)
            const { liquidVariables } = integrationsStore
            liquidVariables.map(liquidVar => {
                parsedObject.top = parsedObject.top?.replace(liquidVar.liquid, liquidVar.default)
                parsedObject.middle = parsedObject.middle?.replace(liquidVar.liquid, liquidVar.default)
            })
            return parsedObject
        },
        allFormsAreValid(){
            return !this.invalidForms.length
        },
        canSavePro(){
            const siteStore = useSiteStore()
            if(siteStore.currentSite.plan === 'paid') return true
            return !this.changingProFeature
        },
        changingProFeature() {
            return (
                keys(useThemesStore().currentTheme)?.length
                && useThemesStore().currentThemeIsPro
            )
        },
        currentTemplateDifference() {
            const template = this.originalTemplates.find(template =>
                template.handle === useUiStore().editingPopHandler,
            ) ?? {}
            return difference(this.currentTemplate, template)
        },
    },
    actions: {
        async applyToAll() {
            try {
                this.loading = true
                await this.UPDATE_ALL_TEMPLATES({
                    payload: {
                        ...this.currentTemplate,
                        ...useThemesStore().currentThemeWithProofs
                    }
                })
                await this.loadTemplates()
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Pops Updated!',
                    subtitle: 'Your settings were successfully updated'
                })
            } catch(e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle:'Oops! Something went wrong and we could not save at this moment.',
                    e
                })
            } finally {
                this.loading = false
            }
        },
        async saveCurrentTemplate(){
            try {
                this.loading = true
                const newTemplate = await this.UPDATE_TEMPLATE({
                    id: this.editingTemplate.id,
                    payload: {
                        ...this.currentTemplate,
                        ...useThemesStore().currentThemeWithProofs
                    }
                })
                this.updateTemplate(newTemplate)
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Pop Updated!',
                    subtitle: `Your settings were successfully updated`
                })
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Could not save the changes!',
                    subtitle:'Oops! Something went wrong and we could not save at this moment.',
                    e
                })
            } finally {
                this.loading = false
            }
        },
        UPDATE_ALL_TEMPLATES({payload}) {
            return this.$nuxt.$axios.$patch(`${process.env.API_V1_URL}/pops/design/all`, payload)
        },
        UPDATE_TEMPLATE({id, payload}) {
            return this.$nuxt.$axios.$put(`templates/${id}`, payload)
        },
        deleteTemplate(id) {
            return this.$nuxt.$axios.$put(`templates/reset/${id}`)
        },
        setupTemplateSettings() {
            this.currentTemplate = {
                ...this.currentTemplate,
                ...this.editingTemplate
            }
        },
        addPageToExcluded(page){
            const excludedPages = this.currentTemplate.proof_exclude_pages

            if(!excludedPages){
               this.SET_EXCLUDE_PAGES([page])
                return
            }

            if(!excludedPages.includes(page)){
               this.SET_EXCLUDE_PAGES( [...excludedPages, page] )
            }
        },
        removePageFromExcluded(page){
            const excludedPages = this.currentTemplate.proof_exclude_pages

            this.SET_EXCLUDE_PAGES(
                excludedPages.filter(excluded => excluded !== page)
            )
        },
        addPageToIncluded(page){
            const includedPages = this.currentTemplate.proof_include_pages

            if(!includedPages){
               this.SET_INCLUDE_PAGES([page])
                return
            }

            if(!includedPages.includes(page)){
               this.SET_INCLUDE_PAGES( [...includedPages, page] )
            }
        },
        removePageFromIncluded(page){
            const includedPages = this.currentTemplate.proof_include_pages

            this.SET_INCLUDE_PAGES(
                includedPages.filter(excluded => excluded !== page)
            )
        },
        async toggleTemplate({handle, value}) {
            this.loading = true
            const template = this.templates.find(template => template.handle === handle)
            if(!template || !handle){
                console.error('template not found', template)
            }

            const body = {
                proof_enabled: value
            }

            try {
                await this.$nuxt.$axios.$put(`templates/${template?.id}`, body)
                // TODO: Use the first request ^^^
                await this.loadTemplates()
                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: value ? 'Pop connected!' : 'Pop disconnected!',
                    subtitle: `Your settings were successfully updated`
                })
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: value ? 'Could not connect!' : 'Could not disconnect!',
                    subtitle:'Oops! Something went wrong and we could not save at this moment.',
                    e
                })
            } finally {
                this.loading = false
            }
        },
        updateTemplate(template){
            const index = this.templates.findIndex(t => t.id === template.id)
            const changingCurrent = this.currentTemplate.id === template.id

            if(changingCurrent) this.currentTemplate = template
            this.templates[index] = template
            this.originalTemplates = this.templates
        },
        SET_CURRENT_TEMPLATE_BODY(body) {
            if(!this.$nuxt.$isPro()) return
            if(!this.currentTemplate?.body) return
            this.currentTemplate.body = body
        },
        SET_EXCLUDE_PAGES(pages) {
            this.currentTemplate.proof_exclude_pages = pages
        },
        SET_INCLUDE_PAGES(pages) {
            this.currentTemplate.proof_include_pages = pages
        },
        UPDATE_CURRENT_TEMPLATE_SETTINGS(settings) {
            this.currentTemplate = {
                ...this.currentTemplate,
                ...settings
            }
        },
        SET_VALID_FORM(form) {
            if(this.invalidForms.includes(form)) {
                const newInvalids = new Set(this.invalidForms)
                newInvalids.delete(form)
                this.invalidForms = [...newInvalids]
            }
        },
        SET_INVALID_FORM(form) {
            if(!this.invalidForms.includes(form)) {
                this.invalidForms = [...(new Set(this.invalidForms)).add(form)]
            }
        },
        async loadTemplates() {
            try {
                const templates = await this.$nuxt.$axios.$get('templates')
                this.templates = templates
                this.originalTemplates = templates
                this.setupTemplateSettings()
            } catch (err) {
                console.error(err)
                // Reload page on templates error
                window.location.href = '/'
            }
        }
    }
})

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
			}
		})
	}
	return changes(object, base)
}
