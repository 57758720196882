import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { useUserStore } from '~/store/user'
export default (nuxt) => {
    const headersConfig = setContext(() => {
        const { authToken } = useUserStore()
        return {
            headers: {
                'auth-token': authToken, // example language header
            }
        }
    });

    const httpLink = new HttpLink({
        uri: nuxt.env.GRAPHQL_API_ENDPOINT || 'https://7ma6zelfj8.execute-api.us-east-1.amazonaws.com/graphql',
    });

    const link = from([
        headersConfig,
        httpLink
    ]);
    const cache = new InMemoryCache();

    return {
        link,
        cache,
        defaultHttpLink: false,
    };
};
