import { useUiStore } from '~/store/ui'
import { defineStore } from 'pinia'
import { timeDurationText } from '@sales-pop/sp-micro'
import { useSiteStore } from './site'

export const useIntegrationsStore = defineStore({
    id: 'integrations',
    state(){
        return {
            popTypes: [{
                title: 'Add to Carts',
                source: 'shopify',
                icon: 'shopping-cart-alt',
                copy: 'You are currently editing the Add to Carts Pop. This Pop syncs Add to Cart Events from your Shopify Store.',
                libraryCopy: 'This Pop syncs Add to Cart Events from your Shopify Store. ',
                integrationHandle: 'shopify_storefront_add_to_cart',
                topTemplate: `{{first_name}} in {{city}} {{province}} {{country}}`,
                middleTemplate: 'Added {{product_title}} to the Cart',
                topLiquidVars: [
                    '{{first_name}}',
                    '{{city}}',
                    '{{province}}',
                    '{{country}}'
                ],
                middleLiquidVars: ['{{product_title}}'],
            },{
                title: 'Cart Summary',
                source: 'shopify',
                icon: 'list-ul',
                libraryCopy: 'This Pop displays the number of items added to cart in a given time range.',
                copy: 'You are currently editing the Cart Summary Pop. This Pop displays the number of items added to cart in a given time range.',
                integrationHandle: 'shopify_cart_summary',
                topLiquidVars: [
                    '{{visitor_count}}',
                    '{{add_to_cart_count}}',
                    '{{time_range}}',
                ],
                disabledSections: ['middle'],
                pro: true,
            },{
                title: 'Add to Carts',
                source: 'wix',
                icon: 'shopping-cart-alt',
                copy: 'You are currently editing the Add to Carts Pop. This Pop syncs Add to Cart Events from your Wix Store.',
                libraryCopy: 'This Pop syncs Add to Cart Events from your Wix Store. ',
                integrationHandle: 'wix_api_add_to_cart',
                topTemplate: `{{first_name}} in {{city}} {{province}} {{country}}`,
                middleTemplate: 'Added {{product_title}} to the Cart',
                topLiquidVars: [
                    '{{first_name}}',
                    '{{city}}',
                    '{{province}}',
                    '{{country}}'
                ],
                middleLiquidVars: ['{{product_title}}']
            },{
                title: 'Abandoned Carts',
                source: 'shopify',
                icon: 'shopping-cart-alt',
                copy: 'You are currently editing the Abandoned Carts Pop. This Pop syncs Abandoned Carts from your Shopify Store.',
                libraryCopy: 'This Pop syncs Abandoned Carts from your Shopify Store.',
                integrationHandle: 'shopify_abandoned_cart',
                topTemplate: `You have an abandoned cart!`,
                middleTemplate: 'Visit cart to complete your purchase.',
                topLiquidVars: [
                    '{{cart_value}}',
                    '{{number_of_products}}',
                ],
                middleLiquidVars: [
                    '{{cart_value}}',
                    '{{number_of_products}}',
                ],
                pro: true
            },{
                title: 'Low Inventories',
                source: 'shopify',
                icon: 'clock',
                copy: 'You are currently editing the Low Inventories Pop. This Pop syncs Low Inventories from your Shopify Store.',
                libraryCopy: 'This Pop syncs Low Inventories from your Shopify Store.',
                integrationHandle: 'shopify_low_inventory',
                topTemplate: `shopify_low_inventory top`,
                middleTemplate: 'shopify_low_inventory middle',
                topLiquidVars: [
                    '{{current_inventory_value}}',
                    '{{product_title}}',
                ],
                middleLiquidVars: [
                    '{{current_inventory_value}}',
                    '{{product_title}}',
                ],
                pro: true
            },{
                title: 'Discounts',
                source: 'shopify',
                icon: 'percentage',
                copy: 'You are currently editing the Discounts Pop. This Pop syncs Discounts from your Shopify Store.',
                libraryCopy: 'This Pop allows to show Discounts from your Shopify Store.',
                integrationHandle: 'shopify_discount',
                topTemplate: `shopify_discount top`,
                middleTemplate: 'shopify_discount middle',
                topLiquidVars: [],
                middleLiquidVars: [],
                pro: true,
                id: "popsRulesAndSettingAddToCartDiscountMenuButton",
            },{
                title: 'Orders',
                source: 'shopify',
                icon: 'shopping-bag',
                copy: 'You are currently editing the Orders Pop. This Pop syncs Orders from your Shopify Store.',
                libraryCopy: 'This Pop syncs Orders from your Shopify Store.',
                integrationHandle: 'shopify_api_purchase',
                topTemplate: `{{first_name}} en {{city}} {{province}} {{country}}`,
                middleTemplate: 'Purchased {{product_title}}',
                topLiquidVars: [
                    '{{first_name}}',
                    '{{city}}',
                    '{{province}}',
                    '{{country}}'
                ],
                middleLiquidVars: ['{{product_title}}']
            },{
                title: 'Order Summary',
                source: 'shopify',
                icon: 'list-ul',
                libraryCopy: 'This Pop displays the number of orders in a given time range.',
                copy: 'You are currently editing the Order Summary Pop. This Pop displays the number of orders in a given time range.',
                integrationHandle: 'shopify_order_summary',
                topLiquidVars: [
                    '{{visitor_count}}',
                    '{{order_count}}',
                    '{{time_range}}',
                ],
                disabledSections: ['middle'],
                pro: true,
            },{
                title: 'Orders',
                source: 'wix',
                icon: 'shopping-bag',
                copy: 'You are currently editing the Orders Pop. This Pop syncs Orders from your Wix Store.',
                libraryCopy: 'This Pop syncs Orders from your Wix Store.',
                integrationHandle: 'wix_api_purchase',
                topTemplate: `{{first_name}} en {{city}}, {{country_code}}`,
                middleTemplate: 'Purchased {{product_title}}',
                topLiquidVars: [
                    '{{first_name}}',
                    '{{city}}',
                    '{{province}}',
                    '{{country}}',
                    '{{country_code}}'
                ],
                middleLiquidVars: ['{{product_title}}']
            },{
                title: 'Recent visitors',
                source: 'shopify',
                icon: 'users-alt',
                copy: 'You are currently editing the Recent Visitors Pop. This Pop displays the number of visits to your site in the last 24 hours and is updated hourly.',
                libraryCopy: 'This Pop displays the number of visits to your site in the last 24 hours and is updated hourly.',
                integrationHandle: 'recent_visitors',
                topTemplate: `{{recent_visitors}} People`,
                middleTemplate: 'Have visited this website recently',
                topLiquidVars: [
                    '{{recent_visitors}}',
                ],
                middleLiquidVars: [],
                pro: true,
            },{
                title: 'Recent visitors',
                source: 'wix',
                icon: 'users-alt',
                copy: 'You are currently editing the Recent Visitors Pop. This Pop displays the number of visits to your site in the last 24 hours and is updated hourly.',
                libraryCopy: 'This Pop displays the number of visits to your site in the last 24 hours and is updated hourly.',
                integrationHandle: 'recent_visitors',
                topTemplate: `{{recent_visitors}} People`,
                middleTemplate: 'Have visited this website recently',
                topLiquidVars: [
                    '{{recent_visitors}}',
                ],
                middleLiquidVars: [],
                pro: true,
            },{
                title: 'Live visitors',
                source: 'shopify',
                icon: 'analysis',
                copy: 'You are currently editing the Live Visitors Pop. This Pop displays the number of visits to your site in the last 6 minutes.',
                libraryCopy: 'This Pop displays the number of visits to your site in the last 6 minutes.',
                integrationHandle: 'live_visitors',
                topTemplate: `{{live_visitors}} People`,
                middleTemplate: 'Are viewing this page right now',
                topLiquidVars: [
                    '{{live_visitors}}',
                ],
                middleLiquidVars: [],
                pro: true,
            },{
                title: 'Live visitors',
                source: 'wix',
                icon: 'analysis',
                copy: 'You are currently editing the Live Visitors Pop. This Pop displays the number of visits to your site in the last 6 minutes.',
                integrationHandle: 'live_visitors',
                topTemplate: `{{live_visitors}} People`,
                middleTemplate: 'Are viewing this page right now',
                topLiquidVars: [
                    '{{live_visitors}}',
                ],
                middleLiquidVars: [],
                pro: true,
            },{
                title: 'Product Reviews',
                source: 'shopify',
                icon: 'star',
                libraryCopy: 'This Pop syncs product reviews from your Shopify Store',
                copy: 'This Pop syncs product reviews from your Shopify Store',
                integrationHandle: 'shopify_review',
                topTemplate: `Reviews top `,
                middleTemplate: 'Reviews mid',
                topLiquidVars: [
                    '{{first_name}}',
                    '{{city}}',
                    '{{province}}',
                    '{{country}}'
                ],
                middleLiquidVars: [
                    '{{product_title}}',
                    '{{rating}}'
                ],
                pro: true,
            },{
                title: 'Welcome Pop',
                source: 'shopify',
                icon: 'grin',
                copy: 'You are currently editing Welcome Pop. This Pop displays a welcome message to your customers.',
                libraryCopy: 'This Pop displays a welcome message to your customers.',
                integrationHandle: 'nudge',
                topTemplate: `Reviews top `,
                middleTemplate: 'Reviews mid',
                pro: true
            }],
        }
    },
    getters: {
        sourceIntegrationHandles(){
            const siteStore = useSiteStore()
            return this.popTypes.filter(popType => popType.source === siteStore.currentSite.source)
        },
        integrationHandleMap(){
            const map = {}
            this.sourceIntegrationHandles.map(popType => map[popType.integrationHandle] = popType)
            return map
        },
        currentIntegration(){
            return this.sourceIntegrationHandles.find(sourceIntegration =>
                sourceIntegration.integrationHandle === useUiStore().editingPopHandler
            )
        },
        liquidVariables(){
            const siteStore = useSiteStore()
            return [{
                title: 'First Name',
                liquid: '{{first_name}}',
                rawLiquid: 'first_name',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Jane'
            },{
                title: 'Province',
                liquid: '{{province}}',
                rawLiquid: 'province',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Florida'
            },{
                title: 'City',
                liquid: '{{city}}',
                rawLiquid: 'city',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Miami'
            },{
                title: 'Country',
                liquid: '{{country}}',
                rawLiquid: 'country',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'United States'
            },{
                title: 'Country Code',
                liquid: '{{country_code}}',
                rawLiquid: 'country_code',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'US'
            },{
                title: 'Product Title',
                liquid: '{{product_title}}',
                rawLiquid: 'product_title',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Classic Kodak Polaroid Camera'
            },{
                title: 'Recent Visitors',
                liquid: '{{recent_visitors}}',
                rawLiquid: 'recent_visitors',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '65'
            },{
                title: 'Live Visitors',
                liquid: '{{live_visitors}}',
                rawLiquid: 'live_visitors',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '18'
            },{
                title: 'Review Rating',
                liquid: '{{rating}}',
                rawLiquid: 'rating',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '<highlight>5 Stars</highlight>'
            },{
                title: 'Cart Count',
                liquid: '{{add_to_cart_count}}',
                rawLiquid: 'add_to_cart_count',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '<highlight>5</highlight>'
            },{
                title: 'Order Count',
                liquid: '{{order_count}}',
                rawLiquid: 'order_count',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '<highlight>15</highlight>'
            },{
                title: 'Visitor Count',
                liquid: '{{visitor_count}}',
                rawLiquid: 'visitor_count',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '<highlight>50</highlight>'
            },{
                title: 'Time Range',
                liquid: '{{time_range}}',
                rawLiquid: 'time_range',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: `<highlight>${timeDurationText(24, siteStore.currentSite.proof_locale)}</highlight>`
            },{
                title: 'Cart Value',
                liquid: '{{cart_value}}',
                rawLiquid: 'cart_value',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '23.08'
            },{
                title: "Number of Products",
                liquid: '{{number_of_products}}',
                rawLiquid: 'number_of_products',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '5'
            },{
                title: "Current Inventory value",
                liquid: '{{current_inventory_value}}',
                rawLiquid: 'current_inventory_value',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: '5'
            },{
                title: "Discount Code",
                liquid: '{{code}}',
                rawLiquid: 'code',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'EXAMPLE',
            },{
                title: 'Customer Buys Item',
                liquid: '{{customer_buys_title}}',
                rawLiquid: 'customer_buys_title',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Classic Kodak Polaroid Camera',
            },{
                title: 'Customer Gets Item',
                liquid: '{{customer_gets_title}}',
                rawLiquid: 'customer_gets_title',
                copy: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                default: 'Photo Album',
            }]
        },
        defaultVariables(){
            return this.liquidVariables
                .map((variable) => ({[variable.rawLiquid]: variable.default}))
                .reduce((prev, current) => ({...prev, ...current}))
        }
    }
})
