import { defineStore } from 'pinia'
import Qs from "qs";

export const usePopsDiscountsStore = defineStore({
    id: 'pops-discounts',
    state() {
        return {
            discounts: [],
            loadingDiscounts: false,
            shopifyDiscounts: [],
            loadingShopifyDiscounts: false,
            processingDiscount: false,
        }
    },
    actions: {
        async loadDiscounts({ title = '' }) {
            this.loadingDiscounts = true
            try {
                const params = {
                    filter: {
                        title: encodeURIComponent(title),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/discounts`,
                    {
                        params,
                        paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
                    }
                )

                this.discounts = response.data
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to fetch discounts!',
                    subtitle: error.response.data.errors.join(".\n"),
                    error
                })
            } finally {
                this.loadingDiscounts = false
            }
        },
        async loadShopifyDiscounts({ title = '' }) {
            this.loadingShopifyDiscounts = true
            try {
                const params = {
                    filter: {
                        title: encodeURIComponent(title),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/pops/discounts/shopify`,
                    {
                        params,
                        paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
                    }
                )

                this.shopifyDiscounts = response.data
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to fetch discounts from Shopify!',
                    subtitle: error.response.data.errors.join(".\n"),
                    error
                })
            } finally {
                this.loadingShopifyDiscounts = false
            }
        },
        async createDiscount(discount) {
            this.processingDiscount = true
            try {
                await this.$nuxt.$axios.$post(
                    `${process.env.API_V1_URL}/pops/discounts`,
                    { discount }
                )

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Discount added!',
                    subtitle: `Discount "${discount.title}" was successfully add to POP!`
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to save discount!',
                    subtitle: error.response.data.errors?.join(".\n"),
                    bottomMessage: error.response.data.errors ? '' : 'Please try again later.',
                    error
                })
            } finally {
                this.processingDiscount = false
            }
        },
        async updateDiscount(discount) {
            this.processingDiscount = true
            try {
                await this.$nuxt.$axios.$patch(
                    `${process.env.API_V1_URL}/pops/discounts/${discount.id}`,
                    { discount }
                )

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Discount updated!',
                    subtitle: `Discount "${discount.title}" was successfully updated!`
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to update discount!',
                    subtitle: error.response.data.errors?.join(".\n"),
                    bottomMessage: error.response.data.errors ? '' : 'Please try again later.',
                    error
                })
            } finally {
                this.processingDiscount = false
            }
        },
        async deleteDiscount(discount) {
            this.processingDiscount = true
            try {
                await this.$nuxt.$axios.$delete(
                    `${process.env.API_V1_URL}/pops/discounts/${discount.id}`,
                    { data: { discount } },
                )

                this.$nuxt.$toasts.add({
                    type: 'success',
                    title: 'Discount deleted!',
                    subtitle: `Discount "${discount.title}" was successfully deleted!`
                })
            } catch (error) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Failed to delete discount!',
                    subtitle: error.response.data.errors?.join(".\n"),
                    bottomMessage: error.response.data.errors ? '' : 'Please try again later.',
                })
            } finally {
                this.processingDiscount = false
            }
        },
    }
})
